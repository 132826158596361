<template>
  <main class="action-page">
    <div class="action-page__skinxs-icon icon-universkin-logo"></div>
    <div v-if="shouldShowMessage">
      <div class="text--level-1">{{ level1Text }}</div>
      <div class="text--level-2">{{ level2Text }}</div>
    </div>
  </main>
</template>

<script>
import { mapActions } from 'vuex';

import { addEmailToBlacklist } from '@/modules/dashboard/api';

import rootTypes from '@/store/types';

export default {
  name: 'UnsubscribeEmailPage',
  props: {
    hashedDoctorId: {
      type: String,
      required: true
    },
    hashedPatientEmail: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      shouldShowMessage: false,
      hasError: false
    };
  },
  computed: {
    level1Text() {
      return this.hasError ? 'Something went wrong' : 'Successfully unsubscribed';
    },
    level2Text() {
      return this.hasError
        ? 'If you need more information, please contact Universkin team for details.'
        : '';
    }
  },
  async mounted() {
    this.setLoading(true);

    const { status } = await addEmailToBlacklist(this.hashedDoctorId, this.hashedPatientEmail);

    this.setLoading(false);

    if (status >= 400 && status < 500) {
      this.hasError = true;
    }

    this.shouldShowMessage = true;
  },
  methods: {
    ...mapActions({
      setLoading: rootTypes.actions.SET_LOADING
    })
  },
  metaInfo: {
    title: 'Unsubscribe',
    titleTemplate: 'skinXs - %s'
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/action-page/action-page-shared';
</style>
